body {
  font-family: 'Inter';
  font-size: 14px;
  background: #F2F6F9;
  color: var(--gray-700);
}
.header, .header-mobile {
  background: #0f73b7;
  padding: 20px 0;
}
.logo {
  background: #1167a2;
  padding: 16px 20px;
}
.logo a {
  display: block;
  line-height: 50px;
}
.logo img {
  max-width: 170px;
}

.icon-button {
  text-align: center;
  background: none;
  border: 0;
  color: white;
  font-size: 20px;
  margin: 0px 4px;
  border-radius: 50%;
  width: 42px;
  height: 42px;
  min-width: 42px;
  line-height: 42px;
  padding: 0 !important;
  -webkit-transition: all .4s ease-out 0s;
  transition: all .4s ease-out 0s;
}

.icon-button:hover {
  background: #0f73b7;
}
/* sidebar */
.section-sidebar {
  background: white;
  width: 290px !important;
  box-shadow: 2px 10px 10px #a3a3a312;
  position: fixed;
  top: 0;
  left: 0;
  padding: 0;
  height: 100%;
  border: 0 !important;
  z-index: 1;

}

.offcanvas-body {
  padding: 0;
}

.offcanvas-body::-webkit-scrollbar-track {

  background-color: #f2f6f9;
}

.offcanvas-body::-webkit-scrollbar {

  width: 6px;
  background-color: #F5F5F5;
}

.offcanvas-body::-webkit-scrollbar-thumb {
  background-color: #dadada;
  border-radius: 36px;
  height: 36px;
}

.sidebar .navbar {
  padding: 20px;
}

.sidebar .navbar-nav {
  flex-direction: column;
  width: 100%;
}

.search-box input::placeholder {
  color: white;
}

.sidebar .navbar-nav li a {
  padding: 0 1.4rem !important;
  line-height: 54px;
  position: relative;
  align-items: center !important;
  justify-content: flex-start !important;
  display: flex !important;
  border-radius: 36px;
  margin: 2px 0;
  color: var(--gray-200);
}
.sidebar .navbar-nav li a.active,
.sidebar .navbar-nav li a:hover {
  background: #0f73b7 !important;
  border-radius: 36px;
  color: white;
  -webkit-transition: all .4s ease-out 0s;
  transition: all .4s ease-out 0s;
}
.sidebar .navbar-nav li a.active:after {
  position: absolute;
  right: 30px;
  font-family: bootstrap-icons!important;
  font-size: 16px;
  content: "\f285";
}
.sidebar .navbar-nav li a i {
  font-size: 20px;
  margin-right: 12px;
}
.menu-divider {
  text-transform: uppercase;
  letter-spacing: 2.2px;
  font-size: 11px;
  padding: 16px 25px;
  font-weight: 500;
  color: #0f73b7;
}

/* sidebar */

/* forms */
.form-control {
    font-size: 13px;
    padding: 12px 20px;
}

.btn {
    padding: 12px 20px;
    font-size: 14px;
    font-weight: 500;
}
.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #0f73b7;
  --bs-btn-border-color: #0f73b7;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5b38e6;
  --bs-btn-hover-border-color: #5b38e6;
  --bs-btn-focus-shadow-rgb: 49,132,253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #5b38e6;
  --bs-btn-active-border-color: #5b38e6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: #0d6efd;
}
.btn:hover {
  background: #1787d3;
  border-color: #1787d3;
}
.form-select, .form-control {
  font-size: 14px;
}
/* forms */

/* card block */
/* card block 1 */
.card-block {
  border-radius: 9px;
  background: white;
  box-shadow: 0px 4px #9bacb80a;
  height: 100%;
}

.card-block-01 {
  padding: 26px 26px 0px 26px;
}

.card-block-01 h2 {
  font-size: 20px;
  font-weight: 700;
  margin: 0;
  color: var(--gray-900);
}

.block-left p {
  margin-bottom: 3.4rem;
  font-size: 13px;
  font-weight: 500;
  color: var(--gray-100);
  opacity: 0.7;
}

.details-button {
  text-decoration: none;
  font-weight: 500;
  align-items: center !important;
  display: flex !important;
  line-height: 22px;
  color: var(--robin-500);
}

.details-button:hover {
  color: var(--robin-800);
}

.details-button i {
  margin-right: 4px;
}

.block-left {
  padding-bottom: 26px;
}

.block-right {
  align-content: baseline;
  display: flex;
  flex-wrap: wrap-reverse;
  max-width: 50%;
}

/* card block 01 */


/* card block 02 */
.card-block-02 .card-content {
  padding: 26px;
}

.card-block-02 .card-title {
  font-size: 19px;
  font-weight: 700;
  line-height: 28px;
  padding: 18px 26px;
  color: var(--gray-800);
  border-bottom: 1px solid var(--air-200);
}

.card-block-02 .result-count {
  font-size: 14px;
  font-weight: 400;
  color: var(--gray-100);
}
canvas {
  max-height: 460px;
}
/* card block 02 */
/* page title */
.page-title {
  background: #ffffff;
  color: #fff;
  padding: 32px 26px;
  border-radius: 9px;
  box-shadow: 0px 4px #9bacb80a;
}

.page-title h1 {
  font-size: 24px;
  margin: 0;
  color: #161616;
  font-weight: 600;
  color: var(--gray-900) !important;
}

.page-title p {
  margin: 0;
  color: #707070;
}

.title-icon {
  background: #0f73b7 !important;
  border-radius: 9px;
  font-size: 26px;
  height: 56px;
  margin-right: 14px;
  min-width: 54px;
  padding: 0px 12px;
  text-align: center;
  line-height: 60px;
}
/* page title */
/* admin modal */
.app-status-modal h3 {
  font-size: 18px;
}
/* admin modal */
/* data table */
.MuiPaper-root {
  box-shadow: none !important;
}
.mobile-section-sidebar {
  z-index: 999999999;
}
.offcanvas-backdrop {
  z-index: 99999999;
}
/* data table */

@media (min-width: 1201px) {
  .header-mobile {
    display: none;
}
.toggle {
  padding-left: 310px;
  padding-top: 2rem;
  padding-right: 20px;
}
.wrapper {
  padding-top: 2rem;
}
}
@media (max-width: 1200px) {
  .header {
    display: none;
}
#sideBar {
    display: none;
}
@media (max-width: 1024px) {

  .wrapper {
    padding-top: 20px;
  }
  }
}
.MuiTableCell-alignCenter {
  border: 1px solid #e3dada;
  border-bottom: 0;
  background: #fbfbfb;
}
